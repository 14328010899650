import { getArticleSettingsPageUrl } from 'src/services/pagesUrl/getArticleSettingsPageUrl';
import { getFeatureFlag } from '../FeatureFlags/getFeatureFlag';
import { TMenuButton } from '../SettingsMenu/types';
import { BookIcon } from '../Icons/BookIcon';
import { AlertTriangleIcon } from '../Icons/AlertTriangleIcon';
import { CollectIcon } from '../Icons/CollectIcon';
import { SavedIcon } from '../Icons/SavedIcon';
import { NewFeatureBadge } from '../Icons/NewFeatureBadge';

export enum ArticleSettingsSubpage {
  general = 'general',
  collectibles = 'collectibles',
  manage = 'manage',
}

export const getMenuButtons = (articleId: string): TMenuButton[] => [
  {
    title: ArticleSettingsSubpage.general,
    icon: <BookIcon />,
    shouldShow: getFeatureFlag('profileSettings'),
    url: getArticleSettingsPageUrl(articleId, ArticleSettingsSubpage.general),
    rightAdornment: <SavedIcon />,
  },
  {
    title: ArticleSettingsSubpage.collectibles,
    icon: <CollectIcon />,
    shouldShow: getFeatureFlag('arweaveIntegration'),
    url: getArticleSettingsPageUrl(articleId, ArticleSettingsSubpage.collectibles),
    rightAdornment: <NewFeatureBadge />,
  },
  {
    title: ArticleSettingsSubpage.manage,
    icon: <AlertTriangleIcon />,
    shouldShow: getFeatureFlag('lensIntegration'),
    url: getArticleSettingsPageUrl(articleId, ArticleSettingsSubpage.manage),
  },
];
