import { useMutation } from '@apollo/client';
import { OutputData } from '@editorjs/editorjs';
import { Article as TArticle, User as TUser } from 't2-graphql-types';
import { useRouter } from 'next/compat/router';
import { ChangeEventHandler, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { updateArticleMutation } from '../mutations/updateArticleById';
import { TUpdateArticleInputData, TArticleEditData, TArticleEditorChangeHandler } from '../types';
import { useArticleEditorForm } from '../useArticleEditorForm';
import { getUpdateArticlePayloadData } from '../data/getUpdateArticlePayloadData';
import { TCustomSelectValue } from '../../Select/types';
import { useOnArticleImagesChangeEffect } from '../hooks/useOnArticleImagesChangeEffect';
import { useOnArticleStatusToggleEffect } from '../hooks/useOnArticleStatusToggleEffect';
import { useAsidePanelContext } from '../../AsidePanel/contexts/AsidePanelContext';
import { useCreateLensPost } from '../hooks/useCreateLensPost';
import getArticleDataForEditorByArticleIdQuery from '../queries/getArticleDataForEditorByArticleId';
import { useRefreshPrivyTokenEffect } from '../hooks/useRefreshPrivyTokenEffect';
import { useAutosaveChanges } from '../hooks/useAutosaveChanges';
import { showPostLensErrorNotification } from '../helpers/showPostLensErrorNotification';
import { showPostErrorNotification } from '../helpers/showPostErrorNotification';
import { UNEXPECTED_ERROR_WHEN_POSTING_ON_LENS } from '../constants/constants';
import { useT2Query } from '../../../hooks/useT2Query';
import { useArticleEditorStatusChangeModal } from '../../ArticleEditorStatusChangeModal/useArticleEditorStatusChangeModal';

type TArticleEditorContextValue = {
  articleId: string;
  title: string;
  body: OutputData;
  slug: string;
  slugError: string;
  readTime: string;
  arweaveId: string | undefined;
  authorAddress: string;
  authorUsername: string;
  lastUpdateDate: string;
  createdAt: string;
  status: string;
  lensPublicationId: string | undefined;
  shouldStoreOnArweave: boolean;
  onTitleChange: ChangeEventHandler<HTMLTextAreaElement>;
  onSlugChange: ChangeEventHandler<HTMLInputElement>;
  setSlugError: (value: string) => void;
  onBodyChange: TArticleEditorChangeHandler;
  onSubmit: () => Promise<void>;
  onToggleStatus: () => void;
  resetForm: () => void;
  isLoading: boolean;
  isDataInitializing: boolean;
  isDataInitialized: boolean;
  isFormInitialized: boolean;
  isSubmitting: boolean;
  hasChanged: boolean;
  isPublished: boolean;
  initialState: TArticleEditData;
  coverImage: File | undefined;
  setCoverImage: (coverImage: File) => void;
  territory: TCustomSelectValue | undefined;
  handleSelectTerritory: (value: TCustomSelectValue) => void;
  handleUpdateStoreOnArweave: (value: boolean) => void;
  author: TUser;
  shouldUploadArticleOnLens: boolean;
  setShouldUploadArticleOnLens: (value: boolean) => void;
  parentArticle: TArticle['parentArticle'];
};

type TArticleEditorContextProviderProps = {
  articleId: string;
  children: ReactNode;
};

export const ArticleEditorContext = createContext<TArticleEditorContextValue>({} as TArticleEditorContextValue);

export const ArticleEditorContextProvider = ({ articleId, children }: TArticleEditorContextProviderProps) => {
  const router = useRouter();
  const { closeAsidePanel } = useAsidePanelContext();
  const [error, setError] = useState<any>(null);
  const [updateArticle] = useMutation<Partial<{ updateArticle: TArticle }>, TUpdateArticleInputData>(
    updateArticleMutation,
    { refetchQueries: [getArticleDataForEditorByArticleIdQuery] },
  );
  const {
    data,
    loading: isDataInitializing,
    called,
  } = useT2Query<{ article: TArticle }>(getArticleDataForEditorByArticleIdQuery, {
    variables: { articleId },
  });
  const isDataInitialized = called && !isDataInitializing;
  const initialState = (data?.article || {}) as TArticleEditData;
  const {
    title,
    body,
    slug,
    setSlugError,
    slugError,
    onSlugChange,
    onBodyChange,
    onTitleChange,
    hasChanged,
    onSubmit,
    isLoading: isArticleEditorFormLoading,
    readTime,
    coverImage,
    setCoverImage,
    territory,
    handleSelectTerritory,
    status,
    toggleStatus,
    shouldUploadArticleOnLens,
    setShouldUploadArticleOnLens,
    shouldStoreOnArweave,
    handleUpdateStoreOnArweave,
    resetToInitialState,
    isInitialized: isFormInitialized,
  } = useArticleEditorForm({ initialState, isDataInitialized });
  const { createLensPost, isPending: isCreateLensPostPending } = useCreateLensPost();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { ModalComponent: ArticleStatusChangeModalComponent, openModal: openStatusChangeModal } =
    useArticleEditorStatusChangeModal({
      error,
      loading: isSubmitting,
      handleSubmitArticleEditor: () => toggleStatus(),
      article: { id: articleId, slug },
    });

  const isLoading = isArticleEditorFormLoading || isDataInitializing || isCreateLensPostPending;

  const handleSubmit = async (formData: TArticleEditData) => {
    const isPublishingArticle = initialState.status !== 'published' && formData?.status === 'published';
    const isAlreadyPublishedOnLens = (initialState as TArticle | undefined)?.lensPublicationId;
    const isPublishingArticleOnLens = isPublishingArticle && !isAlreadyPublishedOnLens && shouldUploadArticleOnLens;

    if (isPublishingArticle) {
      setError(null);
      openStatusChangeModal();
    }

    const updateData = getUpdateArticlePayloadData(formData, isPublishingArticleOnLens);
    try {
      setIsSubmitting(true);
      const res = await updateArticle({ variables: { articleId, data: updateData } });
      const updatedArticle = res.data?.updateArticle;

      const shouldUploadArticleOnLens =
        isPublishingArticleOnLens &&
        updatedArticle?.id &&
        updatedArticle?.shouldUploadArticleOnLens &&
        !updatedArticle?.lensPublicationId;

      if (shouldUploadArticleOnLens) {
        await createLensPost(updatedArticle);
        await updateArticle({
          variables: { articleId, data: { status: 'published' } },
        });
      }

      closeAsidePanel();
    } catch (e) {
      if ((e as Error)?.message?.includes('slug')) {
        setSlugError('*This url is already used');
      } else {
        // eslint-disable-next-line no-console
        console.error({ e });
        toggleStatus('draft');
        setError(e);

        if ((e as any)?.message === UNEXPECTED_ERROR_WHEN_POSTING_ON_LENS) {
          showPostLensErrorNotification();
          return;
        }

        showPostErrorNotification();
      }
    }
  };

  const submitHandler = onSubmit(handleSubmit as any);

  // @ts-ignore
  const value: TArticleEditorContextValue = useMemo(
    () => ({
      articleId,
      arweaveId: data?.article?.arweaveId || undefined,
      title,
      body,
      slug,
      readTime,
      createdAt: data?.article?.createdAt,
      lastUpdateDate: data?.article?.lastUpdateDate,
      lensPublicationId: data?.article?.lensPublicationId || undefined,
      slugError,
      onBodyChange,
      onSlugChange,
      onTitleChange,
      setSlugError,
      onSubmit: submitHandler,
      onToggleStatus: toggleStatus,
      resetForm: resetToInitialState,
      hasChanged,
      isLoading,
      isDataInitializing,
      isDataInitialized,
      isFormInitialized,
      isSubmitting,
      initialState,
      territory,
      handleSelectTerritory,
      author: data?.article?.author!,
      authorAddress: initialState.author?.publicAddress!,
      authorUsername: initialState.author?.username!,
      status: initialState.status!,
      isPublished: initialState.status === 'published',
      coverImage,
      setCoverImage,
      shouldUploadArticleOnLens,
      setShouldUploadArticleOnLens,
      shouldStoreOnArweave,
      handleUpdateStoreOnArweave,
      parentArticle: data?.article?.parentArticle,
    }),
    [
      title,
      body,
      slug,
      shouldStoreOnArweave,
      setSlugError,
      onBodyChange,
      submitHandler,
      isLoading,
      isSubmitting,
      handleSubmit,
      data?.article,
      initialState,
      isDataInitializing,
      coverImage,
      setCoverImage,
      shouldUploadArticleOnLens,
      setShouldUploadArticleOnLens,
      handleUpdateStoreOnArweave,
      resetToInitialState,
      isDataInitialized,
      isFormInitialized,
    ],
  );

  useOnArticleImagesChangeEffect({
    articleId,
    initialBody: (initialState as any).body,
    currentBody: body,
    onImagesChange: submitHandler,
  });

  useOnArticleStatusToggleEffect(data?.article?.status as Required<string | undefined>, status, submitHandler);

  useRefreshPrivyTokenEffect();

  useAutosaveChanges({
    hasUnsavedChanges: router?.pathname === '/editor/[articleId]' ? hasChanged : false,
    body,
    title,
    saveChanges: submitHandler,
  });

  useEffect(() => {
    if (isSubmitting && !hasChanged) {
      setIsSubmitting(false);
    }
  }, [isSubmitting, hasChanged]);

  return (
    <ArticleEditorContext.Provider value={value}>
      <ArticleStatusChangeModalComponent />
      {children}
    </ArticleEditorContext.Provider>
  );
};

export const useArticleEditorContext = () => useContext(ArticleEditorContext);
